<template>
    <Head v-if="product">
        <title>{{ siteTitle }} | {{ product.title }}</title>
        <meta name="description" :content="pageDescription">
    </Head>
    <DefaultLayout v-if="product">
        <TextMasthead background-colour="white" />
        <main>
            <section ref="product-info" class="bg-white">
                <Container class="pb-16 pt-16 lg:pb-32">
                    <a @click="$router.go(-1)" title="Link: Previous Page" class="mb-8 block" data-aos="fade-down"><ArrowSmLeftIcon class="inline h-4 w-4" /> Back</a>
                    <div class="grid grid-cols-12 gap-4 lg:gap-8">
                        <div class="col-span-12 mb-8 lg:col-span-6 lg:mb-0" data-aos="fade-right">
                            <img v-if="product.images.length > 1" :src="product.images[selectedImageIndex].url"
                                 :alt="'Image: ' + product.images[selectedImageIndex].name" class="block m-auto" loading="lazy" />
                            <img v-else :src="product.thumbnail.url"
                                 :alt="'Image: ' + product.thumbnail.name" class="block m-auto" loading="lazy" />
                        </div>
                        <div class="col-span-12 lg:col-span-5 lg:col-start-8" data-aos="fade-left">
                            <h1 class="mb-8">{{ product.title }}</h1>
                            <p class="text-xl mb-8" v-if="product.description">{{ product.description }}</p>
                            <p class="text-2xl mb-8 flex">
                                <span class="font-bold mr-1" v-if="product.child_products.length > 0">From </span>
                                <span class="font-bold mr-1">{{ product.discount_price !== product.price ? moneyFormat(product.discount_price) : moneyFormat(product.price) }}</span>
                                <span class="font-light" v-if="product.discount_price !== product.price">was {{ moneyFormat(product.price) }}</span>
                            </p>

                            <div v-if="product.images.length > 1" class="grid grid-flow-col auto-cols-1/2 scroll-bar py-2 gap-4 items-center lg:auto-cols-1/4 mb-8">
                                <a v-for="(image, index) in product.images" :key="index"
                                   @click.prevent="selectImageIndex(index)"
                                   :class="{'border-2 border-black': selectedImageIndex === index}">
                                    <img :src="image.url" :alt="'Image Thumbnail: ' + image.title" loading="lazy" />
                                </a>
                            </div>

                            <template v-if="product.child_products.length > 0">
                                <label for="product" class="block mb-2">Type</label>
                                <select id="product" class="w-full text-black bg-gray-100 border-0 py-3 px-4" v-model="selectedProductId">
                                    <option>Select</option>
                                    <option v-for="childProduct in product.child_products" :key="childProduct.id" :value="childProduct.id">{{ childProduct.title }} ({{ moneyFormat(childProduct.discount_price) }})</option>
                                </select>
                            </template>

                            <template v-if="!hasInventory">
                                <p class="mt-6 font-semibold">Sorry, this product is currently out of stock.</p>
                            </template>

                            <div v-if="hasInventory" class="flex mt-6 mb-6 lg:mb-8 content-end">
                                <div v-if="!product.referral_url" class="p-3 flex items-center border border-black mr-3">
                                    <label class="mr-3" for="quantity">Qty:</label>
                                    <input id="quantity" type="number" v-model="quantity" class="text-center border-0 inline h-8 p-0" :disabled="!this.selectedProductId"
                                           :max="[product.track_inventory ? product.inventory : 100]" min="1">
                                </div>
                                <LinkButton v-if="product.referral_url" :disabled="!this.selectedProductId" :href="product.referral_url" :title="'Link: ' + product.title" background-colour="black" text-colour="white" :class="'w-full block'">
                                    Buy now
                                </LinkButton>
                                <Button v-else :disabled="!this.selectedProductId" @click.prevent="addProductToBasket(selectedProductId)" background-colour="black" text-colour="white" :class="'w-full block'">
                                    Add to basket
                                </Button>
                            </div>

                            <template v-if="product.attributes.length > 0">
                                <Accordion title="Details" class="py-6">
                                    <div class="grid gap-4 grid-cols-2 lg:grid-cols-3">
                                        <div v-for="(attribute, index) in product.attributes" :key="index">
                                            <h4 class="mb-1">{{ attribute.name }}</h4>
                                            <ul>
                                                <li v-for="(value, i) in attribute.values" :key="i">
                                                    <strong v-if="value.name">{{ value.name }}:</strong> {{ value.value
                                                    }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Accordion>
                            </template>

                            <AccordionList :items="productContent"/>
                        </div>
                    </div>
                </Container>
            </section>
            <div v-if="hasInventory && showBuyNowButtons" class="fixed z-10 bottom-12 w-full hidden lg:block" data-aos="fade-in">
                <Container class="bg-white filter drop-shadow-lg flex items-center justify-between pr-0">
                    <div class="p-3">
                        <span class="text-xl font-bold">{{ product.title }}</span>
                    </div>
                    <div v-if="product.child_products.length > 0" class="p-3">
                        <select class="border-0"
                                v-model="selectedProductId">
                            <option :value="null">Select</option>
                            <option v-for="childProduct in product.child_products" :key="childProduct.id"
                                    :value="childProduct.id">{{ childProduct.title }}
                            </option>
                        </select>
                    </div>
                    <div class="p-3">
                        <span class="text-lg">{{ product.discount_price !== product.price ? moneyFormat(product.discount_price) : moneyFormat(product.price) }}</span>
                    </div>
                    <div v-if="!product.referral_url" class="p-3 flex content-end">
                        <div class="flex items-center text-lg">
                            <label class="mr-3">Qty:</label>
                            <input type="number" v-model="quantity" class="text-center border-0 inline w-8 h-8 p-0">
                        </div>
                    </div>
                    <div>
                        <LinkButton v-if="product.referral_url" :disabled="!this.selectedProductId"
                                    :href="product.referral_url" :title="'Link: ' + product.title"
                                    background-colour="black" text-colour="white" :class="'py-4 h-full'">
                            Buy now
                        </LinkButton>
                        <Button v-else :disabled="!this.selectedProductId"
                                @click.prevent="addProductToBasket(selectedProductId)" background-colour="black"
                                text-colour="white" :class="'py-4 h-full'">
                            Add to basket
                        </Button>
                    </div>
                </Container>
            </div>
            <RelatedProductSection title="You may also like" :products="relatedProducts.length > 0 ? relatedProducts : recommendedProducts"/>
            <RecentlyViewedProductSection title="Recently viewed" background-colour="white" />
            <ContentSections v-if="page" :pageContent="page"/>
        </main>
    </DefaultLayout>
</template>

<script>
    import Container from '@/components/Container.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import AccordionList from '@/components/elements/AccordionList.vue'
    import Accordion from '@/components/elements/Accordion.vue'
    import LinkButton from '@/components/elements/LinkButton.vue'
    import Button from '@/components/elements/Button.vue'
    import ContentSections from '@/components/ContentSections.vue'
    import RelatedProductSection from '@/components/sections/RelatedProductSection.vue'
    import RecentlyViewedProductSection from '@/components/sections/RecentlyViewedProductSection.vue'
    import DefaultLayout from '@/layouts/DefaultLayout.vue'
    import PageContent from '@/mixins/pageContent'
    import PageMeta from '@/mixins/pageMeta'
    import MoneyFormat from '@/mixins/moneyFormat'
    import { ArrowSmLeftIcon } from '@heroicons/vue/outline'
    import { Head } from '@vueuse/head'

    export default {
        name: 'ProductListPage',
        inject: ['sdk', 'dataStore', 'errorHandler'],
        mixins: [PageMeta, PageContent, MoneyFormat],
        components: {
            Accordion,
            Container,
            TextMasthead,
            AccordionList,
            ContentSections,
            RelatedProductSection,
            RecentlyViewedProductSection,
            LinkButton,
            Button,
            DefaultLayout,
            ArrowSmLeftIcon,
            Head
        },
        data() {
            return {
                quantity: 1,
                product: null,
                selectedProductId: null,
                relatedProducts: [],
                recommendedProducts: [],
                selectedImageIndex: 0,
                redirect404: false,
                showBuyNowButtons: false
            };
        },
        computed: {
            pageSlug: function () {
                return 'product';
            },
            productSlug: function () {
                return this.$route.params.slug;
            },
            pageDescription: function () {
                if(this.product.description){
                    return this.product.description
                }

                return this.defaultPageDescription
            },
            hasInventory: function () {
                if (this.product.track_inventory && this.product.inventory <= 0) {
                    return false
                }

                return true
            },
            productContent: function () {
                let productContent = [];

                if (this.product.content.length > 0) {
                    productContent = this.product.content.map(content => {
                            return {
                                title: content.name,
                                content: content.value
                            }
                        }
                    );
                }
                return productContent;
            },
        },
        methods: {
            loadProduct: function () {
                const slug = this.productSlug

                this.sdk.getProductBySlug(slug)
                    .then(response => {
                        if (response.products.length === 0 && this.redirect404) {
                            this.$router.push({name: '404'});
                            return;
                        }

                        this.product = response.products[0];

                        // If we don't have variants then set the selected product to loaded product
                        if (this.product.child_products.length === 0) {
                            this.selectedProductId = this.product.id
                        }

                        if(this.product.related_products){
                            this.relatedProducts = this.product.related_products
                        }

                        this.loadRecommendedProducts(this.product.id)

                        this.sdk.trackAnalyticByProductId(this.product.id)
                        this.dataStore.setRecentlyViewed(this.product)
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
            loadRecommendedProducts: function (productId) {
                this.sdk.getRecommendedProducts([productId])
                    .then(response => {
                        if (response.recommended_products.length === 0) {
                            this.recommendedProducts = []
                            return;
                        }

                        this.recommendedProducts = response.recommended_products;

                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
            addProductToBasket: function (productId) {
                if (!this.dataStore.getBasket()) {
                    this.sdk.createBasket([
                        {
                            product_id: productId,
                            quantity: this.quantity,
                        },
                    ]).then(response => {
                        const basket = response.basket;
                        this.dataStore.setBasket(basket);
                        this.emitter.emit('basket-updated', productId);
                    }).catch(error => {
                        this.errorHandler.handleError(error);
                    })

                    return
                }

                this.sdk.addProductToBasket(this.dataStore.getBasketReference(), productId, this.quantity)
                    .then(response => {
                        const basket = response.basket;
                        this.dataStore.setBasket(basket);
                        this.emitter.emit('basket-updated', productId);
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
            selectImageIndex: function(index){
                this.selectedImageIndex = index;
            },
            shouldShowBuyNowButtons: function(){
                if(window.scrollY >= this.$refs['product-info'].clientHeight){
                    this.showBuyNowButtons = true;
                    return;
                }

                this.showBuyNowButtons = false
            }
        },
        created() {
            this.loadProduct();
            this.loadPageContent();
            window.addEventListener('scroll', this.shouldShowBuyNowButtons);
        }
    }
</script>
<template>
    <header :class="componentThemeClass">
        <template v-if="title">
            <Container class="py-16 lg:py-32" animation="fade-right">
                <div class="lg:max-w-4xl" :class="{'mx-auto text-center': centerText}" >
                    <h1 class="mb-0">{{ title }}
                        <small v-if="subTitle" class="block text-2xl lg:text-4xl">{{subTitle}}</small>
                    </h1>
                    <p v-if="text" class="whitespace-pre-line mb-0 mt-8 text-lg">{{ text }}</p>
                </div>
            </Container>
        </template>
    </header>
</template>

<script>
    import Container from '@/components/Container.vue'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'TextMasthead',
        mixins: [ComponentTheme],
        components: {
            Container
        },
        props: {
            title: String,
            subTitle: String,
            text: String,
            centerText: Boolean
        }
    }
</script>
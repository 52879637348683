<template>
    <div class="relative z-30" :class="[toggler ? 'visible' : 'invisible']">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div class="fixed z-30 inset-0 overflow-y-auto">
            <div class="flex items-center justify-center min-h-full text-center">
                <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all m-2">
                    <div class="bg-white">
                        <XIcon class="absolute h-6 font-bold text-white right-5 top-5" @click="toggler = !toggler" />
                        <a v-if="panel.product" :href="'/product/' + panel.product">
                            <ShoppingBagIcon class="absolute h-6 font-bold text-white left-5 top-5" />
                        </a>
                        <img :src="panel.image.url" class="w-full h-full max-h-95vh" loading="lazy" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { XIcon, ShoppingBagIcon } from '@heroicons/vue/outline'

    export default {
        name: 'ImageModal',
        mixins: [],
        components: {
            XIcon,
            ShoppingBagIcon
        },
        data() {
          return {
              toggler: true
          }
        },
        computed: {
        },
        props: {
            panel: Object
        }
    }
</script>




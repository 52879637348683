<template>
    <section :class="componentThemeClass">
        <Container class="py-16 lg:py-32 lg:max-w-5xl">
            <div class="grid grid-cols-12 gap-4 lg:gap-8">
                <div v-if="content" class="col-span-12 lg:col-span-6" data-aos="fade-right">
                    <div v-html="content" class="lg:max-w-md prose" :class="'prose-' + _textColour"></div>
                </div>
                <div class="col-span-12 gap-8 lg:col-span-6 items-center" data-aos="fade-left">
                    <form v-if="!submitted"
                          name="contact-form"
                          data-netlify="true"
                          netlify-honeypot="bot-field"
                          @submit.prevent="handleSubmit"
                    >
                        <p class="hidden">
                            <label>
                                Don’t fill this out if you’re human: <input name="bot-field" />
                            </label>
                        </p>
                        <div class="mb-4">
                            <label class="block mb-1">Name*</label>
                            <input type="text" name="name" placeholder="Full name*" class="w-full" required />
                        </div>
                        <div class="mb-4">
                            <label class="block mb-1">Email*</label>
                            <input type="email" name="email" placeholder="Email address*" class="w-full" required />
                        </div>
                        <div class="mb-4">
                            <label class="block mb-1">Phone Number</label>
                            <input type="text" name="phone_number" placeholder="Phone number" class="w-full" />
                        </div>
                        <div class="mb-4">
                            <label class="block mb-1">What’s on your mind?*</label>
                            <textarea name="comment" placeholder="Additional information" class="w-full" required></textarea>
                        </div>
                        <input type="hidden" name="form-name" value="contact-form" />
                        <Button background-colour="black" text-colour="white" :class="'mt-6'">Submit</Button>
                    </form>
                    <div v-if="submitted">
                        <div v-if="errors">
                            <p class="h3 mb-4 text-red">Whoops, something went wrong.</p>
                            <p class="text-lg">Sorry, we couldn't submit your requests. Please try again.</p>
                        </div>
                        <div v-else>
                            <p class="h3 mb-4 text-green">Thank you.</p>
                            <p class="text-lg">Thank you for your request. Someone will be in touch shortly to discuss your submission.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    </section>
</template>

<script>
    import axios from 'axios'
    import Container from '@/components/Container.vue'
    import Button from '@/components/elements/Button.vue'
    import StoreContent from '@/mixins/storeContent'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'ContactSection',
        inject: ['dataStore'],
        mixins: [StoreContent, ComponentTheme],
        components: {Container, Button},
        props: {
            content: String
        },
        data() {
            return {
                submitted: false,
                errors: false,
            }
        },
        methods: {
            handleSubmit: function (event) {
                const myForm = event.target;
                const formData = new FormData(myForm)

                axios.post("/",
                    new URLSearchParams(formData).toString(),
                    {header: {"Content-Type": "application/x-www-form-urlencoded"}}
                ).then(response => {
                    this.submitted = true
                    this.errors = false
                })
                    .catch(error => {
                        this.submitted = true
                        this.errors = true
                        console.debug(error)
                    })
            }
        }
    }
</script>
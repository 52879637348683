<template>
    <DefaultLayout>
        <TextMasthead title="Collections" :center-text="true" />
        <main>
            <ProductGroupSection v-for="(productGroup, index) in productGroups" :key="index" :product-group="productGroup"
                                 :background-colour="index % 2 === 0 ? 'white' : 'transparent'" />
            <template v-if="this.loadingProducts === true">
                <div class="relative h-24">
                    <Loading/>
                </div>
            </template>
            <div v-if="showLoadMore" class="py-6 lg:py-8">
                <Button @click.prevent="loadMoreProductGroups" text-colour="black"
                        :class="'mt-2 mx-auto w-full block lg:max-w-xl'">{{ this.loadingProducts ? 'Loading...' : 'Load more' }}</Button>
            </div>
        </main>
    </DefaultLayout>
</template>

<script>
    import TextMasthead from '@/components/TextMasthead.vue'
    import DefaultLayout from '@/layouts/DefaultLayout.vue'
    import PageContent from '@/mixins/pageContent'
    import ProductGroupSection from '@/components/sections/ProductGroupSection.vue'
    import Button from '@/components/elements/Button.vue'
    import Loading from '@/components/Loading.vue'

    export default {
        name: 'CollectionListPage',
        inject: ['sdk', 'dataStore', 'errorHandler'],
        mixins: [PageContent],
        components: {
            TextMasthead,
            DefaultLayout,
            Loading,
            ProductGroupSection,
            Button
        },
        data() {
            return {
                pageNumber: 1,
                pagination_interval: 4,
                productGroups: null,
                showLoadMore: false,
                loadingProducts: false
            }
        },
        computed: {
            pagination: function () {
                return {
                    page: this.pageNumber,
                    pagination_interval: this.pagination_interval
                }
            },
        },
        methods: {
            loadMoreProductGroups: function () {
                this.pageNumber++
                this.loadProductGroups(true, this.pagination);
            },
            loadProductGroups: function (append = false, pagination = {page: 1}) {
                this.loadingProducts = true
                if (!append) {
                    this.products = [];
                    this.showLoadMore = false
                }

                this.sdk.getProductGroups(pagination, [], [], ['*'])
                    .then(response => {
                        this.productGroups = response.product_groups

                        if (append) {
                            this.productGroups = [...this.productGroups, ...response.product_groups]
                        } else {
                            this.productGroups = response.product_groups
                        }

                        this.showLoadMore = response.current_page !== response.last_page
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                }) .finally(() => {
                    this.loadingProducts = false
                });
            },
        },
        created() {
            this.loadProductGroups(false, this.pagination)
        }
    }
</script>
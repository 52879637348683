<template>
    <CheckoutLayout v-if="basket">
        <TextMasthead />
        <main>
            <Container class="h-screen overflow-auto" animation="fade-in">
                <iframe :src="checkoutUrl" width="100%" height="100%" />
            </Container>
        </main>
    </CheckoutLayout>
</template>

<script>
    import Container from '@/components/Container.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import BasketActions from '@/mixins/basketActions'
    import StoreContent from '@/mixins/storeContent'
    import CheckoutLayout from '@/layouts/CheckoutLayout.vue'

    export default {
        name: 'CheckoutPage',
        inject: ['sdk', 'dataStore'],
        mixins: [StoreContent, BasketActions],
        components: {
            CheckoutLayout,
            Container,
            TextMasthead,
        },
        computed: {
            checkoutUrl: function () {
                let queryParams = {
                    embed: true
                }

                if (this.dataStore.getPartnerReference()) {
                    queryParams['partner_reference'] = this.dataStore.getPartnerReference()
                }

                return 'https://checkout.sugar-coat.io/' + this.store.slug + '/' + this.basket.basket_reference + '/?' + new URLSearchParams(queryParams).toString()
            }
        },
        created() {
            if (!this.basketHasProducts) {
                this.$router.push({name: 'basket'});
                return;
            }
        }
    }
</script>
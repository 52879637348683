<template>
    <DefaultLayout>
        <TextMasthead title="Your Details" />
        <main>
            <section>
                <Container class="py-16">
                    <div class="grid grid-cols-12 gap-4 lg:gap-16">
                        <div class="mb-8 col-span-12 lg:mb-0 lg:col-span-3" data-aos="fade-right">
                            <AccountNavigation/>
                        </div>
                        <div class="col-span-12 lg:col-span-6" data-aos="fade-left">
                            <Alert v-if="updateUserErrors.length > 0" :messages="updateUserErrors"/>
                            <Alert v-if="updateUserSuccess" :messages="['Details successfully updated']" :theme="'success' "/>
                            <form v-on:submit.prevent="updateUser($event)">
                                <div class="lg:flex lg:justify-between lg:space-x-4">
                                    <label class="mb-4 block lg:w-1/2">
                                        <span class="text-gray-700">First Name*</span>
                                        <input name="first_name" type="text" class="w-full" :value="user.first_name" />
                                    </label>
                                    <label class="mb-4 block lg:w-1/2">
                                        <span class="text-gray-700">Last Name*</span>
                                        <input name="last_name" type="text" class="w-full" :value="user.last_name" />
                                    </label>
                                </div>
                                <div class="mb-4">
                                    <label>
                                        <span class="text-gray-700">Email address*</span>
                                        <input name="email" type="email" class="w-full" :value="user.email" />
                                    </label>
                                </div>
                                <div class="mb-4">
                                    <label class="inline-flex items-center">
                                        <input name="change_password" type="checkbox" :value="true" v-model="changePassword">
                                        <span class="ml-2">Change password?</span>
                                    </label>
                                </div>
                                <div v-if="changePassword" class="lg:flex lg:justify-between lg:space-x-4">
                                    <label class="mb-4 block lg:w-1/2">
                                        <span class="text-gray-700">Password*</span>
                                        <input name="password" type="password" class="w-full" required>
                                    </label>
                                    <label class="mb-4 block lg:w-1/2">
                                        <span class="text-gray-700">Confirm Password*</span>
                                        <input name="confirm_password" type="password" class="w-full" required>
                                    </label>
                                </div>
                                <Button background-colour="black" text-colour="white" :class="'mt-6 mb-16'">Update</Button>
                            </form>
                       </div>
                   </div>
               </Container>
           </section>
       </main>
    </DefaultLayout>
</template>

<script>
   import Container from '@/components/Container.vue'
   import TextMasthead from '@/components/TextMasthead.vue'
   import AccountNavigation from '@/components/AccountNavigation.vue'
   import Alert from '@/components/elements/Alert.vue'
   import Button from '@/components/elements/Button.vue'
   import DefaultLayout from '@/layouts/DefaultLayout.vue'

   export default {
       name: 'AccountDetailsPage',
       inject: ['dataStore', 'sdk'],
       components: {
           Container,
           TextMasthead,
           AccountNavigation,
           Alert,
           Button,
           DefaultLayout
       },
       data() {
           return {
               updateUserErrors: [],
               updateUserSuccess: false,
               changePassword: false
           };
       },
       computed: {
           user: function () {
               return this.dataStore.getUser()
           }
       },
       methods: {
           updateUser: function (event) {
               this.updateUserErrors = [];
               this.updateUserSuccess = false;
               const userData = {
                   email: event.target.elements.email.value,
                   first_name: event.target.elements.first_name.value,
                   last_name: event.target.elements.last_name.value,
               }

               if(this.changePassword){
                   if(
                       event.target.elements.password.value !==
                       event.target.elements.confirm_password.value
                   ){
                       this.updateUserErrors = [
                           'Passwords must match'
                       ];
                       return;
                   }

                   userData['password'] = event.target.elements.password.value;
               }

               this.sdk.updateUser(this.user.id, userData).then(response => {
                   this.dataStore.setUser(response.user)
                   this.updateUserSuccess = true;
               }).catch(error => {
                   if ('errors' in error.response.data) {
                       this.updateUserErrors = [
                           error.response.data.errors[0].message
                       ];
                   } else {
                       this.updateUserErrors = [
                           'Error processing your request'
                       ];
                   }
               })
           },
       }
   }
</script>
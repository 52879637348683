export default {
    props: {
        backgroundColour: {
            type: String,
            default: null
        },
        textColour: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            _backgroundColour: 'transparent',
            _textColour: 'black'
        }
    },
    computed: {
        backgroundColourClass: function () {
            return this._backgroundColour ? 'bg-' + this._backgroundColour : ''
        },
        textColourClass: function () {
            return this._textColour ? 'text-' + this._textColour : ''
        },
        componentThemeClass: function() {
            return this.backgroundColourClass + ' ' + this.textColourClass
        }
    },
    created() {
        if(this.backgroundColour){
            this._backgroundColour = this.backgroundColour
        }
        if( this.textColour) {
            this._textColour = this.textColour
        }
    }
}
export default {
    computed: {
        basket: function () {
            return this.dataStore.getBasket()
        },
        basketSubTotal: function () {
            return this.basket ? this.basket.sub_total : 0
        },
        basketDiscountTotal: function () {
            return this.basket ? this.basket.discount_total : 0
        },
        basketTotal: function () {
            return this.basket ? this.basket.total : 0
        },
        basketHasProducts: function () {
            return this.basket && this.basket.products.length > 0
        },
    },
    methods: {
        formatBasketProductPrice: function (basketProduct, quantity = 1) {
            if (basketProduct.product.discount_price !== basketProduct.product.price)
                return basketProduct.product.discount_price * quantity;

            return basketProduct.product.price * quantity;
        },
        updateProductQuantity: function (quantity, productId) {
            if (quantity <= 0) {
                return false;
            }

            this.sdk.addProductToBasket(this.dataStore.getBasketReference(), productId, quantity)
                .then(response => {
                    const basket = response.basket;

                    this.dataStore.setBasket(basket);
                }).catch(error => {
                console.log(error);
            })
        },
        removeProductFromBasket: function (productId) {
            this.sdk.deleteProductFromBasket(this.dataStore.getBasketReference(), productId)
                .then(response => {
                    const basket = response.basket;

                    this.dataStore.setBasket(basket);
                }).catch(error => {
                console.log(error);
            })
        },
    }
}
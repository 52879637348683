<template>
    <Head v-if="productGroup">
        <title>{{ siteTitle }} | {{ productGroup.title }}</title>
        <meta name="description" :content="pageDescription">
    </Head>
    <DefaultLayout>
        <Masthead v-if="productGroup.thumbnail" :title="productGroup.title" sub-title="Collection" :image="productGroup.thumbnail.url"
                  text-colour="white"
                  background-colour="white"
        />
        <TextMasthead v-else :title="productGroup.title" sub-title="Collection" :center-text="true" />
        <main>
            <IntroTextSection v-if="productGroup.description" :text="productGroup.description" />
            <section>
                <Container class="py-16 lg:py-32">
                    <div class="min-h-24">
                        <div v-if="productGroup.products && productGroup.products.length > 0"
                             class="grid grid-cols-12 gap-4 mb-8 lg:gap-8 lg:mb-16">
                            <div v-for="(product, index) in productGroup.products" :key="index" class="col-span-6 lg:col-span-3" data-aos="fade-in" :data-aos-delay="index">
                                <Product :product="product" />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </main>
    </DefaultLayout>
</template>

<script>
    import Container from '@/components/Container.vue'
    import Masthead from '@/components/Masthead.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import DefaultLayout from '@/layouts/DefaultLayout.vue'
    import Product from '@/components/elements/Product.vue'
    import PageContent from '@/mixins/pageContent'
    import PageMeta from '@/mixins/pageMeta'
    import IntroTextSection from '@/components/sections/IntroTextSection.vue'
    import { Head } from '@vueuse/head'

    export default {
        name: 'CollectionPage',
        inject: ['sdk', 'dataStore', 'errorHandler'],
        mixins: [PageMeta, PageContent],
        components: {
            Container,
            Masthead,
            TextMasthead,
            DefaultLayout,
            Product,
            IntroTextSection,
            Head
        },
        data() {
            return {
                productGroup: null
            }
        },
        computed: {
            pageDescription: function () {
                if (this.productGroup && this.productGroup.description) {
                    return this.productGroup.description
                }

                return this.defaultPageDescription
            },
        },
        methods: {
            loadProductGroup: function () {
                const typeSlug = this.$route.params.slug;

                this.sdk.getProductGroupBySlug(typeSlug)
                    .then(response => {
                        this.productGroup = response.product_groups[0]
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
        },
        created() {
            this.loadProductGroup()
        }
    }
</script>
<template>
    <section v-for="(panel, index) in panels" :key="index" :class="'bg-' + panel.background_colour + ' text-' + panel.text_colour">
        <Container class="py-16 lg:py-32">
            <div class="grid grid-cols-12 gap-4 lg:gap-32">
                <div class="col-span-12 lg:col-span-6" :class="{'lg:order-2': index % 2 === 0}" :data-aos="index % 2 ? 'fade-right' : 'fade-left'">
                    <img v-if="panel.image" :src="panel.image.url" class="max-w-full" :alt="'Image: ' + panel.title" loading="lazy" />
                </div>
                <div class="col-span-12 lg:col-span-6 self-center" :data-aos="index % 2 ? 'fade-left' : 'fade-right'">
                    <h3 class="lg:text-5xl">{{ panel.title }}</h3>
                    <span v-html="panel.content" class="prose" :class="'prose-' + panel.text_colour"></span>
                </div>
            </div>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container.vue'
    export default {
        name: 'ContentPanelSection',
        components: {Container},
        props: {
            panels: Array
        }
    }
</script>
<template>
    <DefaultLayout>
        <header>
            <Container class="text-center pt-16 lg:pt-32 lg:max-w-4xl">
                <h1 data-aos="fade-right mb-8">Search results</h1>
                <p class="mb-0 text-xl lg:text-3xl" data-aos="fade-in">We found {{ totalProducts }} products for
                    <input type="text" v-model="query" @keyup.prevent="searchChange()"
                           class="block font-bold m-auto text-center mt-3 border-0 border-b border-black bg-transparent italic outline-0 text-xl lg:text-3xl" />
                </p>
            </Container>
        </header>
        <main>
            <section>
                <Container class="py-16 lg:py-32">
                    <div class="min-h-24">
                        <div v-if="products && products.length > 0"
                             class="grid grid-cols-12 gap-4 mb-8 lg:gap-8 lg:mb-16">
                            <div v-for="(product, index) in products" :key="index" class="col-span-6 lg:col-span-3" data-aos="fade-in" :data-aos-delay="index">
                                <Product :product="product" />
                            </div>
                        </div>
                        <div v-if="!this.loadingProducts && products.length === 0">
                            <p class="text-2xl mb-0 h-24 flex justify-center items-center">
                                <span>No results found</span>
                            </p>
                        </div>
                        <template v-if="this.loadingProducts === true">
                            <div class="relative h-24">
                                <Loading/>
                            </div>
                        </template>
                        <div v-if="showLoadMore" class="mt-6 lg:mt-8" data-aos="fade-up">
                            <span v-if="this.products.length > 0" class="text-sm text-center block">
                                Showing <span class="font-bold">{{ this.products.length }}</span> of <span class="font-bold">{{ totalProducts }}</span> products
                            </span>
                            <Button @click.prevent="loadMoreProducts" text-colour="black"
                                    :class="'mt-2 mx-auto w-full block lg:max-w-xl'">{{ this.loadingProducts ? 'Loading...' : 'Load more' }}</Button>
                        </div>
                    </div>
                </Container>
            </section>
        </main>
    </DefaultLayout>
</template>

<script>
    import DefaultLayout from '@/layouts/DefaultLayout.vue'
    import Product from '@/components/elements/Product.vue'
    import Button from '@/components/elements/Button.vue'
    import Loading from '@/components/Loading.vue'
    import Container from '@/components/Container.vue'

    export default {
        name: 'SearchPage',
        inject: ['sdk', 'dataStore', 'errorHandler'],
        components: {
            Container,
            Loading,
            DefaultLayout,
            Product,
            Button
        },
        data() {
            return {
                query: null,
                pageNumber: 1,
                pagination_interval: 16,
                totalProducts: 0,
                sorting: {
                    property: 'created_at',
                    direction: 'desc'
                },
                sortingOptions: [
                    {
                        property: 'price',
                        direction: 'asc',
                        label: 'Price Low to High'
                    },
                    {
                        property: 'price',
                        direction: 'desc',
                        label: 'Price High to Low'
                    },
                    {
                        property: 'created_at',
                        direction: 'desc',
                        label: 'Newest'
                    }
                ],
                products: [],
                showLoadMore: false,
                loadingProducts: false
            };
        },
        computed: {
            pagination: function () {
                return {
                    page: this.pageNumber,
                    pagination_interval: this.pagination_interval
                }
            },
        },
        methods: {
            loadMoreProducts: function () {
                this.pageNumber++
                this.loadProducts(true, this.pagination, this.filters, [this.sorting]);
            },
            searchChange: function() {
                this.products = [];
                this.totalProducts = 0

                if( this.query &&  this.query.length >= 3 && !this.loadingProducts){
                    this.loadProducts()
                }
            },
            loadProducts: function (append = false, pagination = {page: 1}, filters = [], sort = [], includes = []) {
                this.loadingProducts = true

                if (!append) {
                    this.products = [];
                    this.showLoadMore = false
                }

                if(this.productType) {
                    filters.push({
                        property: 'product_type_id',
                        value: this.productType.id,
                        operator: '='
                    })
                }

                this.sdk.searchProducts(this.query, pagination, filters, sort, includes)
                    .then(response => {
                        if (append) {
                            this.products = [...this.products, ...response.products]
                        } else {
                            this.products = response.products;
                        }

                        this.showLoadMore = response.current_page !== response.last_page
                        this.totalProducts = response.count
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                }) .finally(() => {
                    this.loadingProducts = false
                });
            }
        },
        created() {
            if (this.$route.query.query) {
                this.query = this.$route.query.query
            }

            if(this.query) {
                this.loadProducts();
            }
        }
    }
</script>
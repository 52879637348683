<template>
    <section class="relative bg-cover bg-center flex items-center" :class="componentThemeClass" :style="image ? {backgroundImage: `url('${image}')`} : {}">
        <Container class="text-center lg:max-w-4xl" :class="[image ? 'py-32 lg:py-64' : 'py-16 lg:py-32']">
            <h2 v-if="title" class="p-4 text-shadow" data-aos="fade-right">
                {{ title }}
            </h2>
            <p v-if="text" class="whitespace-pre-line text-2xl mb-0" data-aos="fade-in">{{ text }}</p>
            <LinkButton v-if="linkUrl" :link="linkUrl" :text-colour="_textColour" :background-colour="_backgroundColour"
                        class="rounded-theme border mt-8 inline-block" :class="' border-' + _textColour" data-aos="fade-up">
                {{ linkText }}
            </LinkButton>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container.vue'
    import ComponentTheme from '@/mixins/componentTheme'
    import LinkButton from '@/components/elements/LinkButton.vue'

    export default {
        name: 'ImageBannerSection',
        mixins: [ComponentTheme],
        components: {LinkButton, Container},
        props: {
            title: String,
            linkUrl: String,
            linkText: String,
            text: String,
            image: String,
        },
        created() {
            if(this.image){
                this._backgroundColour = null
            }
        }
    }
</script>
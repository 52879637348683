<template>
    <button class="transition-all text-center py-3 px-12 border disabled:opacity-50 disabled:pointer-events-none rounded-theme" :class="buttonClass" >
        <slot></slot>
    </button>
</template>

<script>
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'Button',
        mixins: [ComponentTheme],
        computed: {
            buttonClass: function(){
                let cssClass = [this.componentThemeClass];

                if(this._backgroundColour === 'transparent'){
                    cssClass.push('border-' + this._textColour)
                    cssClass.push('hover:bg-' + this._textColour)
                }
                else{
                    cssClass.push('border-' + this._backgroundColour)
                    cssClass.push('hover:bg-transparent')
                }

                if(this._textColour === 'white'){
                    cssClass.push('hover:text-black')
                }

                if(this._textColour === 'black'){
                    cssClass.push('hover:text-white')
                }

                return cssClass.join(' ');
            }
        }
    }
</script>




<template>
    <DefaultLayout>
        <TextMasthead title="Your Account" />
        <main>
            <section>
                <Container class="py-16">
                    <div class="grid grid-cols-12 gap-8 lg:gap-16">
                        <div class="col-span-12 lg:col-span-3" data-aos="fade-right">
                            <AccountNavigation/>
                        </div>
                        <div class="col-span-12 lg:col-span-9" data-aos="fade-left">
                            <p class="text-xl">Welcome <span class="font-bold">{{ user.email }}</span> to your account.</p>
                            <p>You can review your orders and edit your details here.</p>
                        </div>
                    </div>
                </Container>
            </section>
            <RelatedProductSection title="Products recommended for you" :products="[]"/>
        </main>
    </DefaultLayout>
</template>

<script>
    import Container from '@/components/Container.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import AccountNavigation from '@/components/AccountNavigation.vue'
    import DefaultLayout from '@/layouts/DefaultLayout.vue'
    import RelatedProductSection from '@/components/sections/RelatedProductSection.vue'

    export default {
        name: 'AccountPage',
        inject: ['dataStore'],
        components: {
            Container,
            TextMasthead,
            AccountNavigation,
            RelatedProductSection,
            DefaultLayout
        },
        computed: {
            user: function () {
                return this.dataStore.getUser()
            }
        }
    }
</script>
<template>
    <section :class="componentThemeClass">
        <Container class="py-16 lg:py-32 lg:max-w-4xl">
            <h2 v-if="title" class="mb-16 text-center" data-aos="fade-right">{{ title }}</h2>
            <div data-aos="fade-in">
                <AccordionList :background-colour="_backgroundColour" :text-colour="_textColour" :items="items"/>
            </div>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container.vue'
    import AccordionList from '@/components/elements/AccordionList.vue'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'AccordionSection',
        mixins: [ComponentTheme],
        components: {Container, AccordionList},
        props: {
            title: String,
            items: Array
        }
    }
</script>
<template>
    <DefaultLayout>
        <TextMasthead title="Order" :sub-title="order.code"/>
        <main>
            <section>
                <Container class="py-16">
                    <div class="grid grid-cols-12 gap-4 lg:gap-16">
                        <div class="mb-8 col-span-12 lg:mb-0 lg:col-span-3" data-aos="fade-right">
                            <AccountNavigation/>
                        </div>
                        <div class="col-span-12 lg:col-span-9" data-aos="fade-left">
                            <table class="w-full">
                                <tr class="border-b">
                                    <th class="text-left font-light py-4">Product</th>
                                    <th class="hidden text-left font-light py-4 lg:block">Price</th>
                                    <th class="text-left font-light py-4">Quantity</th>
                                    <th class="text-right font-light py-4">Total</th>
                                </tr>
                                <tr v-for="product in order.products" :key="product.id" class="align-top">
                                    <td class="py-4">
                                        <div class="flex flex-col lg:flex-row">
                                            <img v-if="product.image" class="w-full lg:max-w-xs lg:mr-4"
                                                 :src="product.image" loading="lazy" />
                                            <div>
                                                <h4 class="my-2 mb-0 lg:mt-0">{{ product.title }}</h4>
                                                <p class="mb-0 mt-2" v-if="product.description">{{
                                                    product.description }}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="hidden py-4 lg:block">
                                        <p class="text-xl mb-0">{{ moneyFormat(product.price) }}</p>
                                    </td>
                                    <td class="py-4">
                                        <p class="mb-0">x{{ product.quantity }}</p>
                                    </td>
                                    <td class="py-4 text-right">
                                        <p class="font-bold mr-1 text-xl mb-0">{{ moneyFormat(product.sub_total) }}</p>
                                    </td>
                                </tr>
                            </table>
                            <div class="border-t py-16">
                                <div class="ml-auto lg:w-1/3">
                                    <p class="flex justify-between text-2xl mb-2">
                                        <span>Subtotal</span><span>{{ moneyFormat(order.sub_total) }}</span></p>
                                    <p class="flex justify-between text-2xl mb-2">
                                        <span>Delivery</span><span>{{ moneyFormat(order.delivery_cost_total) }}</span>
                                    </p>
                                    <p v-if="order.discount_total > 0" class="flex justify-between text-2xl mb-2">
                                        <span>Discount</span><span>-{{ moneyFormat(order.discount_total) }}</span>
                                    </p>
                                    <p class="flex justify-between text-3xl font-bold">
                                        <span>Total</span><span>{{ moneyFormat(order.total) }}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </main>
    </DefaultLayout>
</template>

<script>
    import TextMasthead from '@/components/TextMasthead.vue'
    import AccountNavigation from '@/components/AccountNavigation.vue'
    import DefaultLayout from '@/layouts/DefaultLayout.vue'
    import Container from '@/components/Container.vue'
    import MoneyFormat from '@/mixins/moneyFormat'

    export default {
        name: 'AccountOrderPage',
        inject: ['sdk', 'dataStore', 'errorHandler'],
        mixins: [MoneyFormat],
        components: {
            Container,
            TextMasthead,
            AccountNavigation,
            DefaultLayout
        },
        data() {
            return {
                order: null
            };
        },
        computed: {
            user: function () {
                return this.dataStore.getUser()
            }
        },
        created() {
            if (!this.user.customer) {
                this.$router.push({name: '404'});
                return
            }

            const id = this.$route.params.id;

            this.sdk.getCustomerOrder(this.user.customer.id, id)
                .then(response => {
                    this.order = response.order
                    if (!this.order) {
                        this.$router.push({name: '404'});
                        return
                    }
                }).catch(error => {
                this.errorHandler.handleError(error);
                this.$router.push({name: '404'});
                return
            })
        }
    }
</script>
<template>
    <div class="border-b" :class="componentThemeClass + ' border-' + _textColour">
        <a @click.prevent="open = !open" data-aos="fade-right">
            <h3 class="mb-0 flex items-center justify-between text-xl">{{ title }}
                <ChevronUpIcon v-if="open" class="inline h-8 w-8" />
                <ChevronDownIcon v-else class="inline h-8 w-8" />
            </h3>
        </a>
        <div class="mt-4" :class="{'hidden' : !open}">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import ComponentTheme from '@/mixins/componentTheme'
    import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/outline'

    export default {
        name: 'Accordion',
        mixins: [ComponentTheme],
        components: {
            ChevronDownIcon, ChevronUpIcon
        },
        props: {
            title: String
        },
        data() {
            return {
                open: false
            }
        }
    }
</script>
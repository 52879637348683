import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import '../node_modules/aos/dist/aos.css'
import {ApiWrapper, Config} from '@sugarcoat/js-sdk'
import dataStore from './store/data'
import errorHandler from './mixins/errorHandler'
import mitt from 'mitt'
const emitter = mitt();
import * as Sentry from '@sentry/vue'
import {createHead} from '@vueuse/head'

Config.configure({
    baseUrl: import.meta.env.VITE_APP_API_BASE_URL,
    key: import.meta.env.VITE_APP_API_PUBLIC_KEY
});

Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN
});

const head = createHead()
const sdk = new ApiWrapper();
const app = createApp(App, {sdk: sdk, errorHandler: errorHandler})

app.use(router)
app.use(head)

app.provide('sdk', sdk)
app.provide('dataStore', dataStore)
app.provide('errorHandler', errorHandler)

app.config.globalProperties.emitter = emitter;
app.config.errorHandler = (err) => {
    Sentry.captureException(err);
}

router.isReady().then(() => {
    app.mount('#app')
})

<template>
    <section :class="componentThemeClass">
        <Container class="py-16 lg:py-32">
            <div class="text-center max-w-4xl mx-auto">
                <p class="whitespace-pre-line text-2xl lg:text-3xl" data-aos="fade-right">{{ text }}</p>
                <a v-if="linkUrl" class="block mt-8 text-2xl lg:text-3xl" :href="linkUrl" :title="'Link: ' + linkText" data-aos="fade-up">{{ linkText }}</a>
            </div>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container.vue'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'IntroTextSection',
        mixins: [ComponentTheme],
        components: {Container},
        props: {
            text: String,
            linkUrl: String,
            linkText: String
        }
    }
</script>
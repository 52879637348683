<template>
    <section v-if="products && products.length > 0" :class="componentThemeClass">
        <Container class="py-16 lg:py-32">
            <div class="flex justify-between items-center mb-8">
                <h2 v-if="title"  class="mb-0" data-aos="fade-right">{{ title }}</h2>
                <a v-if="linkUrl"  :href="linkUrl" :title="'Link: ' + linkText" :text-colour="_textColour" class="text-xl" data-aos="fade-left">
                    {{ linkText }}</a>
            </div>
            <div class="grid grid-flow-col auto-cols-1/2 scroll-bar pb-8 gap-4 lg:gap-8 lg:auto-cols-1/4">
                <div v-for="(product, index) in products" :key="index" data-aos="fade-in" :data-aos-delay="index * 50">
                    <Product :product="product" />
                </div>
            </div>
        </Container>
    </section>
</template>

<script>
    import Product from '@/components/elements/Product.vue'
    import Container from '@/components/Container.vue'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'RelatedProductSection',
        mixins: [ComponentTheme],
        components: {
            Product,
            Container
        },
        props: {
            title: String,
            linkUrl: String,
            linkText: String,
            products: Array
        }
    }
</script>
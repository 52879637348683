<template>
    <a :href="$router.resolve({name: 'product', params: { slug: product.slug }}).href" :title="'Link: ' + product.title">
        <div v-if="product.thumbnail" class="relative bg-white overflow-hidden pb-[100%]">
            <img :src="product.thumbnail.url" :alt="'Image: ' + product.title" class="left-0 right-0 top-0 bottom-0 max-h-full max-w-full m-auto absolute" loading="lazy" />
        </div>
        <div v-else-if="product.images.length > 0" class="relative bg-white overflow-hidden pb-[100%]">
            <img :src="product.images[0].url" :alt="'Image: ' + product.title" class="left-0 right-0 top-0 bottom-0 max-h-full max-w-full m-auto absolute" loading="lazy" />
        </div>
        <h3 class="mt-4 mb-2">{{ product.title }}</h3>
        <p class="mb-0 flex justify-between">
            <span class="font-bold mr-1">{{ product.discount_price !== product.price ? moneyFormat(product.discount_price) : moneyFormat(product.price) }}</span>
            <span class="font-light" v-if="product.discount_price !== product.price">was {{ moneyFormat(product.price) }}</span>
        </p>
    </a>
</template>

<script>
    import MoneyFormat from '@/mixins/moneyFormat'

    export default {
        name: 'Product',
        mixins: [MoneyFormat],
        props: {
            product: Object
        }
    }
</script>
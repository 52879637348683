export default {
    data() {
        return {
            redirect404: false
        };
    },
    computed: {
        page: function () {
            return this.dataStore.getPage()
        },
        pageSlug: function () {
            return this.$route.params.slug;
        }
    },
    methods: {
        async loadPageContent () {
            const slug = this.pageSlug

            this.sdk.getPageBySlug(slug)
                .then(response => {
                    let pageContent = null;
                    if (response.pages.length === 0 && this.redirect404) {
                        this.$router.push({name: '404'});
                        return;
                    }

                    if(response.pages.length > 0) {
                        pageContent = response.pages[0];

                       pageContent.content.forEach(content => {
                            if(content.content_type) {
                                try {
                                    const jsonContent = JSON.parse(content.value);
                                    content.value = jsonContent
                                } catch (e) {
                                    console.debug(e)
                                }
                            }
                        })
                    }

                    this.dataStore.setPage(pageContent)
                }).catch(error => {
                console.log(error);
            })
        },
        getPageContentByKey: function (key) {
            const content = this.page.content.filter(content => content.content_type && content.content_type.key === key)

            if(content.length > 0){
                return content[0]
            }

            return null;
        }
    }
}
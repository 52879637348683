<template>
    <nav v-if="store" class="w-full sticky top-0 pt-2 pb-2 z-20 drop-shadow-md" :class="[navClass, componentThemeClass]">
        <Container>
            <div class="flex items-center justify-between">
                <div>
                    <router-link :to="{name: 'home'}" title="Link: Home">
                        <img :src="siteSettings.value.logo.url" :alt="'Image: ' + store.name + ' Logo'" class="max-h-[50px] lg:max-h-[75px]" loading="lazy" />
                    </router-link>
                </div>
                <div class="hidden flex items-center ml-auto lg:block px-4">
                    <ul v-if="primaryNavigation" class="flex text-lg" :class="textColourClass">
                        <li v-for="(link, index) in primaryNavigation.value.links" :key="index" class="mr-8">
                            <a :href="link.url" :title="'Link: ' + link.title">{{ link.title }}</a>
                        </li>
                    </ul>
                </div>
                <div class="ml-auto">
                    <ul class="flex text-lg" :class="textColourClass">
                        <li v-if="store.features.includes('baskets')" class="ml-4 lg:ml-6 hidden lg:block">
                            <a :title="'Link: Search'" @click.prevent="searchToggle"><SearchIcon  class="inline h-6 w-6" /></a>
                        </li>
                        <li v-if="store.features.includes('baskets')" class="ml-4 lg:ml-6">
                            <router-link :to="{ name: 'basket'}" title="Link: Basket">
                                <ShoppingBagIcon class="inline h-6 w-6" />({{ basket ? basket.products.length : '0' }})
                            </router-link>
                        </li>
                        <li v-if="store.features.includes('baskets') && !dataStore.loggedIn()" class="ml-4 lg:ml-6">
                            <router-link :to="{ name: 'login'}" title="Link: Login"><UserIcon class="inline h-6 w-6" /></router-link>
                        </li>
                        <li v-if="store.features.includes('baskets') && dataStore.loggedIn()" class="ml-4 lg:ml-6">
                            <router-link :to="{ name: 'account'}" title="Link: Account"><UserIcon class="inline h-6 w-6" /></router-link>
                        </li>
                        <li v-if="dataStore.loggedIn()" class="ml-4 lg:ml-6">
                            <a href="#" @click="logout()"><LogoutIcon class="inline h-6 w-6" /></a>
                        </li>
                    </ul>
                </div>
                <transition name="fade" mode="out-in">
                    <div v-if="menuOpen" class="z-50 top-0 left-0 fixed block h-screen w-screen bg-white lg:hidden">
                        <Container class="h-full flex items-center">
                            <div class="absolute text-black top-2 left-4 z-50 lg:hidden">
                                <router-link :to="{name: 'home'}" title="Link: Home">
                                    <img :src="siteSettings.value.logo.url" :alt="'Image: ' + store.name + ' Logo'" class="max-h-[50px] lg:max-h-[75px]" loading="lazy" />
                                </router-link>
                            </div>
                            <a class="absolute text-black top-4 right-4 z-50 lg:hidden" @click.prevent="menuToggle">
                                <XIcon class="inline h-8 w-8" />
                            </a>
                            <div class="">
                                <ul class="px-4 text-black text-2xl">
                                    <li v-for="(link, index) in primaryNavigation.value.links" :key="index" class="mb-2">
                                        <a :href="link.url" :title="'Link: ' + link.title">{{ link.title }}</a>
                                    </li>
                                    <li v-if="store.features.includes('baskets')" class="mb-2">
                                        <router-link :to="{ name: 'search'}" title="Link: Search">Search</router-link>
                                    </li>
                                    <li v-if="store.features.includes('baskets')" class="mb-2">
                                        <router-link :to="{ name: 'basket'}" title="Link: Basket">Basket ({{ basket ? basket.products.length : '0' }})</router-link>
                                    </li>
                                    <li v-if="store.features.includes('baskets') && !dataStore.loggedIn()">
                                        <router-link :to="{ name: 'login'}" title="Link: Login">Login</router-link>
                                    </li>
                                    <li v-if="dataStore.loggedIn()" class="mb-2">
                                        <router-link :to="{ name: 'account'}" title="Link: Account">Account</router-link>
                                    </li>
                                    <li v-if="dataStore.loggedIn()">
                                        <a href="#" @click="logout()">Logout</a>
                                    </li>
                                </ul>
                            </div>
                        </Container>
                    </div>
                </transition>
                <div class="block ml-4 lg:hidden">
                    <a @click.prevent="menuToggle">
                        <MenuIcon class="inline h-8 w-8" :class="textColourClass" />
                    </a>
                </div>
            </div>
            <BasketAlert ref="basket" />
        </Container>
        <div v-if="searchOpen" class="hidden lg:block">
            <Container class="py-16 lg:max-w-4xl">
                <form :action="searchFormUrl" class="flex items-center border-b-2" :class="' border-' + _textColour">
                    <button><SearchIcon class="inline h-8 w-8" /></button>
                    <input type="text" name="query" class="border-0 bg-transparent text-2xl w-full" placeholder="What are you looking for?" />
                    <a @click.prevent="searchToggle"><XIcon class="inline h-8 w-8" /></a>
                </form>
            </Container>
        </div>
    </nav>
</template>

<script>
    import { MenuIcon, XIcon, ShoppingBagIcon, UserIcon, LogoutIcon, SearchIcon } from '@heroicons/vue/outline'
    import Container from '@/components/Container.vue'
    import BasketAlert from '@/components/BasketAlert.vue'
    import StoreContent from '@/mixins/storeContent'
    import SiteContent from '@/mixins/siteContent'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'Navigation',
        inject: ['dataStore'],
        mixins: [ComponentTheme, StoreContent, SiteContent],
        components: {
            Container,
            BasketAlert,
            MenuIcon,
            XIcon,
            ShoppingBagIcon,
            UserIcon,
            LogoutIcon,
            SearchIcon
        },
        props: {
            position: String
        },
        data() {
            return {
                menuOpen: false,
                searchOpen: false
            }
        },
        computed: {
            basket: function () {
                return this.dataStore.getBasket()
            },
            primaryNavigation: function () {
                return this.getSiteContentByKey('primary_navigation')
            },
            siteSettings: function () {
                return this.getSiteContentByKey('site_settings')
            },
            searchFormUrl: function () {
                return this.$router.resolve({name: 'search'}).href
            },
            navClass: function(){
                let navClass = []

                if(this.searchOpen && this._textColour === 'white'){
                    navClass.push('lg:bg-black')
                }

                if(this.searchOpen && this._textColour === 'black'){
                    navClass.push('lg:bg-white')
                }

                return navClass.join(' ');
            }
        },
        methods: {
            menuToggle: function () {
                this.menuOpen = !this.menuOpen
            },
            basketToggle: function () {
                this.$refs.basket.basketToggle()
            },
            searchToggle: function () {
                this.searchOpen = !this.searchOpen
            },
            logout: function(){
                this.dataStore.logout()
                this.$router.push({name: 'login'});
            }
        }
    }
</script>
<template>
    <section :class="componentThemeClass">
        <Container class="pt-2 lg:max-w-4xl">
            <div v-if="content" class="text-center" data-aos="fade-right">
                <span v-html="content" class="prose" :class="'prose-' + _textColour"></span>
            </div>
            <iframe scrolling="no" :src=donationLink></iframe>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container.vue'
    import StoreContent from '@/mixins/storeContent'
    import ComponentTheme from '@/mixins/componentTheme'
    export default {
        name: 'DonationSection',
        inject: ['dataStore'],
        mixins: [StoreContent, ComponentTheme],
        components: {Container},
        props: {
            content: String
        },
        computed: {
            donationLink: function () {
                return "https://donate.sugar-coat.io/" + this.store.slug + "?embed=true&progress=false"
            }
        }
    }
</script>
<template>
    <footer>
        <div v-if="outputNavigation && footerContent" :class="['bg-' + footerContent.value.background_colour]">
            <Container class="py-16 lg:py-24">
                <div class="grid grid-cols-12 gap-4 lg:gap-8" :class="[footerContent.value.panels.length < 4 ? 'lg:grid-cols-none lg:grid-flow-col' : '']">
                    <div v-for="(panel, index) in footerContent.value.panels" :key="index" class="mb-4 col-span-6 lg:col-span-3 lg:mb-0" :class="['text-' + footerContent.value.text_colour]" data-aos="fade-in">
                        <h4 class="font-bold">{{ panel.title }}</h4>
                        <ul v-if="panel.title" class="lg:text-md">
                            <li v-for="(link, index) in panel.list" :key="index" class="mb-2">
                                <a :href="link.link_url" :title="'Link: ' + link.title">{{ link.title }}</a>
                            </li>
                        </ul>
                        <p v-if="panel.text" class="whitespace-pre-line">{{ panel.text }}</p>
                    </div>
                    <div v-if="footerContent.value.social" class="mb-4 col-span-6 lg:col-span-3 lg:mb-0" :class="['text-' + footerContent.value.text_colour]" data-aos="fade-in" data-aos-delay="300">
                        <h4 class="font-bold">{{ footerContent.value.social.title }}</h4>
                        <ul v-if="footerContent.value.social.title" class="lg:text-md">
                            <li v-for="(link, index) in footerContent.value.social.list" :key="index" class="mb-2 inline">
                                <a :href="link.link_url" :title="'Link: ' + link.social">
                                    <img class="inline pr-4" :src="'/icons/' + link.social + '.svg'" :alt="'Image ' + link.social" loading="lazy" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </div>
        <div v-if="store" class="bg-black text-white">
            <Container class="py-2 text-right">
                <p class="text-sm mb-0">&copy; {{ store.name }} {{ (new Date()).getFullYear()}}</p>
            </Container>
        </div>
    </footer>
</template>

<script>
    import Container from '@/components/Container.vue'
    import SiteContent from '@/mixins/siteContent'
    import StoreContent from '@/mixins/storeContent'

    export default {
        name: 'Footer',
        inject: ['dataStore'],
        mixins: [StoreContent, SiteContent],
        components: {Container},
        props: {
            outputNavigation: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            footerContent: function () {
                return this.getSiteContentByKey('footer')
            }
        },
    }
</script>
<template>
    <DefaultLayout>
        <TextMasthead title="Reset your password"/>
        <main>
            <section>
                <Container class="py-16 lg:py-32">
                    <div class="grid grid-cols-12">
                        <div v-if="requestNewPassword" class="col-span-12 mb-16 pb-16 lg:mb-0 lg:pb-0 lg:border-b-0 lg:col-span-6 lg:pr-32"
                        data-aos="fade-up">
                            <h3 class="mb-8">Forgotten password</h3>
                            <template v-if="forgottenPasswordSuccess">
                                <p class="text-xl">Password reset request has been successfully created.</p>
                                <p>Check your emails to reset your password.</p>
                            </template>
                            <template v-else>
                                <p>Enter your email address below and if you have an account we will email you password reset instructions.</p>
                                <Alert v-if="forgottenPasswordErrors.length > 0" :messages="forgottenPasswordErrors" />
                                <form v-on:submit.prevent="forgottenPassword($event)">
                                    <div class="mb-4">
                                        <label>
                                            <span class="text-gray-700">Email address*</span>
                                            <input name="email" type="email" class="w-full" required />
                                        </label>
                                    </div>
                                    <Button background-colour="black" text-colour="white" :class="'mt-6'">Reset password</Button>
                                </form>
                            </template>
                        </div>
                        <div v-if="!requestNewPassword" class="col-span-12 mb-16 pb-16 lg:mb-0 lg:pb-0 lg:border-b-0 lg:col-span-6 lg:pr-32"
                             data-aos="fade-up">
                            <h3 class="mb-8">Reset password</h3>
                            <template v-if="resetPasswordSuccess">
                                <p class="text-xl">Your password has been successfully reset.</p>
                                <p>You can now <router-link :to="{name: 'login'}" title="Link: Login" class="font-bold">login</router-link> using these details.</p>
                            </template>
                            <template v-else>
                                <Alert v-if="resetPasswordErrors.length > 0" :messages="resetPasswordErrors" />
                                <form v-on:submit.prevent="resetPassword($event)">
                                    <div class="mb-4">
                                        <label class="block">
                                            <span class="text-gray-700">Password*</span>
                                            <input name="password" type="password" class="w-full" required />
                                        </label>
                                    </div>
                                    <div class="mb-4">
                                        <label class="block">
                                            <span class="text-gray-700">Confirm Password*</span>
                                            <input name="confirm_password" type="password" class="w-full" required />
                                        </label>
                                    </div>
                                    <Button background-colour="black" text-colour="white" :class="'mt-6'">Reset password</Button>
                                </form>
                            </template>
                        </div>
                    </div>
                </Container>
            </section>
        </main>
    </DefaultLayout>
</template>

<script>
    import Container from '@/components/Container.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import Alert from '@/components/elements/Alert.vue'
    import Button from '@/components/elements/Button.vue'
    import DefaultLayout from '@/layouts/DefaultLayout.vue'

    export default {
        name: 'PasswordResetPage',
        inject: ['dataStore', 'sdk'],
        components: {
            Container,
            TextMasthead,
            Alert,
            Button,
            DefaultLayout
        },
        computed: {
            code: function () {
                return 'code' in this.$route.query ? this.$route.query.code : null;
            }
        },
        data() {
            return {
                requestNewPassword: true,
                forgottenPasswordErrors: [],
                forgottenPasswordSuccess: false,
                resetPasswordErrors: [],
                resetPasswordSuccess: false,
            };
        },
        methods: {
            forgottenPassword: function (event) {
                this.forgottenPasswordErrors = [];
                this.forgottenPasswordSuccess = false;

                this.sdk.forgottenPassword(event.target.elements.email.value).then(response => {
                    console.debug(response)
                   this.forgottenPasswordSuccess = true;
                }).catch(error => {
                    if('errors' in error.response.data){
                        this.forgottenPasswordErrors = [
                            error.response.data.errors[0].message
                        ];
                    }
                    else {
                        this.forgottenPasswordErrors = [
                            'Error processing your request'
                        ];
                    }
                })
            },
            resetPassword: function (event) {
                this.resetPasswordErrors = [];
                this.resetPasswordSuccess = false;

                if(
                    event.target.elements.password.value !==
                    event.target.elements.confirm_password.value
                ){
                    this.resetPasswordErrors = [
                        'Passwords must match'
                    ];
                    return;
                }

                this.sdk.resetPassword(this.code, event.target.elements.password.value).then(response => {
                    console.debug(response)
                    this.resetPasswordSuccess = true;
                }).catch(error => {
                    if('errors' in error.response.data){
                        this.resetPasswordErrors = [
                            error.response.data.errors[0].message
                        ];
                    }
                    else {
                        this.resetPasswordErrors = [
                            'Error processing your request'
                        ];
                    }
                })
            }
        },
        created() {
            if(this.code) {
                this.requestNewPassword = false
            }
        }
    }
</script>
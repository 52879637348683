<template>
    <header class="bg-cover bg-center" :class="[componentThemeClass]" :style="image ? {backgroundImage: `url('${image}')`} : {}">
        <Container class="text-center lg:max-w-4xl" animation="fade-right" :class="[image ? 'py-32 lg:py-64' : 'py-16 lg:py-32']">
            <h1 v-if="title" :class="image ? 'text-shadow-md' : ''">{{ title }}</h1>
            <div v-if="subTitle">
                <small  class="block text-2xl lg:text-4xl" :class="image ? 'text-shadow-md' : ''">{{subTitle}}</small>
            </div>
            <p v-if="text" class="whitespace-pre-line mb-0 mt-8 text-lg">{{ text }}</p>
        </Container>
    </header>
</template>

<script>
    import ComponentTheme from '@/mixins/componentTheme'
    import Container from '@/components/Container.vue'

    export default {
        name: 'Masthead',
        mixins: [ComponentTheme],
        components: {
            Container
        },
        props: {
            title: String,
            subTitle: String,
            text: String,
            image: String,
            height: String
        },
        created() {
            if(this.image){
                this._backgroundColour = null
            }
        }
    }
</script>
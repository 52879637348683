import {createRouter, createWebHistory} from 'vue-router'
import HomePage from '@/pages/HomePage.vue'
import GeneralPage from '@/pages/GeneralPage.vue'
import ProductListPage from '@/pages/ProductListPage.vue'
import ProductPage from '@/pages/ProductPage.vue'
import BasketPage from '@/pages/BasketPage.vue'
import LoginPage from '@/pages/LoginPage.vue'
import PasswordResetPage from '@/pages/PasswordResetPage.vue'
import ActivationPage from '@/pages/ActivationPage.vue'
import AccountPage from '@/pages/AccountPage.vue'
import AccountOrderListPage from '@/pages/AccountOrderListPage.vue'
import AccountOrderPage from '@/pages/AccountOrderPage.vue'
import AccountDetailsPage from '@/pages/AccountDetailsPage.vue'
import AccountAddressPage from '@/pages/AccountAddressPage.vue'
import CheckoutPage from '@/pages/CheckoutPage.vue'
import NotFoundPage from '@/pages/NotFoundPage.vue'
import CollectionListPage from '@/pages/CollectionListPage.vue'
import CollectionPage from '@/pages/CollectionPage.vue'
import SearchPage from '@/pages/SearchPage.vue'
import data from '@/store/data'

const authenticationGuard = function (to, from, next) {
    if (data.getUser()) {
        next();
    } else {
        next('/login');
    }
}

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage
    },
    {
        path: '/pages/:slug',
        name: 'page',
        component: GeneralPage
    },
    {
        path: '/products/:type?',
        name: 'products',
        component: ProductListPage,
        meta: {title: 'Products'}
    },
    {
        path: '/products/:category/:option',
        name: 'product-categories',
        component: ProductListPage,
        meta: {title: 'Category'}
    },
    {
        path: '/product/:slug',
        name: 'product',
        component: ProductPage,
        meta: {title: 'Product'}
    },
    {
        path: '/collections',
        name: 'collections',
        component: CollectionListPage,
        meta: {title: 'Collections'}
    },
    {
        path: '/collection/:slug',
        name: 'collection',
        component: CollectionPage,
        meta: {title: 'Collection'}
    },
    {
        path: '/search',
        name: 'search',
        component: SearchPage,
        meta: {title: 'Search'}
    },
    {
        path: '/basket',
        name: 'basket',
        component: BasketPage,
        meta: {title: 'Basket'}
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: CheckoutPage,
        meta: {title: 'Checkout'}
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        meta: {title: 'Login'}
    },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: PasswordResetPage,
        meta: {title: 'Password Reset'}
    },
    {
        path: '/activate',
        name: 'activate',
        component: ActivationPage,
        meta: {title: 'Activate Account'}
    },
    {
        path: '/account',
        name: 'account',
        component: AccountPage,
        meta: {title: 'Account'},
        beforeEnter : authenticationGuard,
    },
    {
        path: '/account/orders',
        name: 'orders',
        component: AccountOrderListPage,
        meta: {title: 'Account Orders'},
        beforeEnter : authenticationGuard
    },
    {
        path: '/account/orders/:id',
        name: 'order',
        component: AccountOrderPage,
        meta: {title: 'Account Order'},
        beforeEnter : authenticationGuard
    },
    {
        path: '/account/details',
        name: 'details',
        component: AccountDetailsPage,
        meta: {title: 'Account Details'},
        beforeEnter :authenticationGuard
    },
    {
        path: '/account/address',
        name: 'address',
        component: AccountAddressPage,
        meta: {title: 'Account Address'},
        beforeEnter : authenticationGuard
    },
    {
        path: '/404',
        name: '404',
        meta: {title: '404'},
        component: NotFoundPage
    },
    {
        path: '/:catchAll(.*)',
        name: 'catchall',
        meta: {title: '404'},
    }
]


const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return {top: 0}
    }
})
export default router